import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Home" keywords={['gatsby', 'application', 'react']} mdxType="SEO" />
    <h1>{`Hi annoying girl`}</h1>
    <h3>{`This is your site`}</h3>
    <h3>{`Write some shit here`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      